define("frontend/pods/provider/route", ["exports", "frontend/mixins/provider-route-mixin", "frontend/pods/base-route", "frontend/utils/plugin-redirect"], function (exports, _providerRouteMixin, _baseRoute, _pluginRedirect) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend(_providerRouteMixin.default, {
    breadCrumb: null,
    beforeModel: function beforeModel(transition) {
      // Clean up the load indicator.
      $(".ember-load-indicator").remove();

      if (this.get("sessionUser") && !this.get("sessionUser.piiSupported")) {
        this.transitionTo('unsupported-pii');
        return;
      }

      // This lets ourselves know we're doing a provider login and not a patient.
      sessionStorage.setItem("authenticationType", "provider");

      // Are we authenticated?
      if (!this.get("session.isAuthenticated")) {
        if ((0, _pluginRedirect.shouldInternalRedirect)()) {
          // For users running the mirah plugin who aren't logged into
          // this site we can actually just SSO them and get them on their way.
          (0, _pluginRedirect.redirectInternalUser)(this);
          return;
        }

        // No: we need to do an authentication pass.
        if (transition.targetName === "provider.sso-auth") {
          return this._super.apply(this, arguments);
        }

        if (this.get("institute.current.ssoSettings.forceSsoRedirect")) {
          transition.abort();

          // This comes in from redirects via provider-ui
          var destination = new URLSearchParams(window.location.search).get("targetUrl");

          var ssoUrl = "/auth/v1/sso/init?destination=" + encodeURIComponent(destination || window.location.pathname + window.location.search);
          this.set("session.attemptedTransition", transition);
          window.location.assign(ssoUrl);
          return;
        }

        if (this.get("exceptions").includes(transition.targetName)) {
          return this._super.apply(this, arguments);
        }

        transition.abort();
        this.set("session.attemptedTransition", transition);
        this.transitionTo(this.get("authenticationRoute"));
        return false;
      } else if (this.get("session.data.authenticated.user_type") !== "provider" && this.get("session.data.authenticated.user_type") !== "internal") {
        // We're authenticated, but we're not logging in a provider
        // or internal.

        // Silently log out, then hard redirect to the url given.
        transition.abort();
        this.get("sessionUser").logout();
        return false;
      } else {
        // We're authenticated and we should forward the user to their
        // desired page.

        // if already logged-in, don't show the login page

        // TODO: if the roll out for schedule page completes
        // remove "provider.assessment.dashboard" === transition.TargetName
        // the `/` route resolves to provider.assessment.dashboard
        // which we want to feature gate on to redirect to provider-ui schedule page
        // this catch should cover any ember links to provider.assessment.dashboard
        // or / that somewhere else gets converted to provider.assessment.dashboard
        if (this.authenticationRoute === transition.targetName || "provider.assessment.dashboard" == transition.targetName) {
          // for internal users on the internal domain,
          // redirect to the ops internal dashboard page instead of a dashboard
          if ((0, _pluginRedirect.internalInstituteLoginRedirect)(this)) {
            return;
          }
          // TODO: if the roll out for schedule page completes
          // remove this if clause
          if (this.get("features.enableDashboardSchedule")) {
            window.location.assign("/app/providers/dashboard/schedule");
            return;
          }
          if (this.get("features.enableCollaborativeCare")) {
            window.location.assign("/app/cocm/dashboard");
            return;
          }
          return this.transitionTo("provider.assessment.dashboard");
        }
        return this._super.apply(this, arguments);
      }
    }
  });
});