define('frontend/pods/components/reporting/drilldown-table-entity-collection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sessionUser: Ember.inject.service(),
    tagName: '',
    expanded: false,
    loaded: false,
    showAll: false,
    hasMore: false,
    isFirstLoad: true,
    showRow: true,

    childLevel: Ember.computed('level', function () {
      return this.get('level') + 1;
    }),

    reportSettings: Ember.computed('collection.path', 'parentReportSettings', function () {
      return this.get("parentReportSettings").forCollection(this.get("collection"));
    }),

    defaultLimit: Ember.computed.alias('reportSettings.limit'),

    hasMoreData: Ember.computed('reportSettings.limit', 'children.length', function () {
      return this.get("children.length") >= this.get("reportSettings.limit");
    }),

    hasLimit: Ember.computed('defaultLimit', function () {
      var limit = this.get("defaultLimit");
      return limit && limit !== 'none';
    }),

    paramsChangedObserver: Ember.observer('collection', 'reportSettings.reportRequestData', function () {
      var _this = this;

      // We may receive multiple property changes during the same run cycle, so wait to
      // trigger the request
      if (this.get("expanded") && this.get("collection")) {
        Ember.run.later(function () {
          return _this._loadCollection();
        });
      }
    }),

    // Currently we allow all users to download data in csv.
    showDownload: true,

    _loadCollection: function _loadCollection() {
      var _this2 = this;

      var requestData = this.get("reportSettings.reportRequestData");

      this.setProperties({ isLoading: true, isError: false });

      var onSuccess = function onSuccess(result) {
        var total = result.get('reportEntityTreeNodes.length');
        var hasMore = requestData.limit && requestData.limit !== 'none' && total >= requestData.limit;
        _this2.setProperties({ children: result.get('reportEntityTreeNodes'), hasMore: hasMore, isLoading: false, isFirstLoad: false, metric: result });
        $('table.analytics-table').floatThead('reflow');
      };

      var onError = function onError() {
        _this2.set("isError", true);
      };

      return this.store.createRecord('drilldown-metric', requestData).save().then(onSuccess, onError);
    },


    // Change only the limit so that we can generate a full CSV.
    _generateFullCsv: function _generateFullCsv() {
      var _this3 = this;

      var requestData = this.get("reportSettings.reportRequestData");

      var requestDataNoLimit = Ember.merge(requestData, { limit: 'none' });

      var onSuccess = function onSuccess(result) {
        _this3.set('loadingCsv', false);
        result.downloadCsv();
      };

      var onError = function onError() {
        _this3.set('loadingCsv', false);
      };

      this.set('loadingCsv', true);

      return this.store.createRecord('drilldown-metric', requestDataNoLimit).save().then(onSuccess, onError);
    },


    actions: {
      toggleExpanded: function toggleExpanded() {
        if (!this.get("expanded") && !this.get("loaded")) {
          this._loadCollection();
        }

        if (this.get("expanded")) {
          this.get("reportViewData").onHide(this.get('level'));
        } else {
          this.get("reportViewData").onExpand(this.get('level'));
        }

        this.toggleProperty('expanded');
      },
      removeLimit: function removeLimit() {
        this.set("reportSettings.limit", 'none');
        this._loadCollection();
      },
      addLimit: function addLimit() {
        this.set("reportSettings.limit", this.get('reportSettings.defaultLimit') || 10);
        this._loadCollection();
      },
      downloadCsv: function downloadCsv() {
        if (this.get('hasMore')) {
          // We always want to download all the data for CSVs, so if there's more data to display, we want to trigger
          // a special request which has no limit and then download that.
          this._generateFullCsv();
        } else {
          this.get('metric').downloadCsv();
        }
      }
    }
  });
});