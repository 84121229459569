define('frontend/models/assessment/report-settings', ['exports', 'frontend/models/assessment/report-comparison', 'lodash'], function (exports, _reportComparison, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    availablePopulations: Ember.computed("populations.[]", "availablePopulationMnemonics", function () {
      var available = this.getWithDefault("availablePopulationMnemonics", []);
      return this.getWithDefault("populations", []).filter(function (s) {
        return s != null && available.includes(s.get('mnemonic'));
      });
    }),
    defaultPopulation: Ember.computed('anyReportIncludesNorse', 'availablePopulations.[]', function () {
      // Select the NORSE in preference to the peabody as it can't be broken down further.
      if (this.get("anyReportIncludesNorse")) {
        return this.get("availablePopulations").find(function (p) {
          return p.get('mnemonic') === 'general';
        });
      } else {
        if (this.get("availablePopulations.length") > 1) {
          return this.get("availablePopulations").find(function (p) {
            return p.get('mnemonic') === 'intreatment';
          });
        } else {
          return this.get("availablePopulations.firstObject");
        }
      }
    }),
    // This will get overridden on change
    currentPopulation: Ember.computed.oneWay('defaultPopulation'),
    note: {
      isShown: false,
      isHtmlView: false
    },
    compareReportTo: 'previous',
    compareReportToOptions: ['intake', 'previous'],
    showNormChanger: Ember.computed.gt("availablePopulationMnemonics.length", 0),
    availablePopulationMnemonics: Ember.computed('allReports.[]', function () {
      var types = {};

      this.getWithDefault("allReports", []).forEach(function (report) {
        if (report.get("includesNorse")) {
          types['norse'] = true;
        }

        if (report.get("includesPeabody")) {
          types['ptpb'] = true;
        }
      });

      var populations = [];

      if (types.norse) {
        populations.pushObjects(['outpatient', 'inpatient', 'general']);
      }

      if (types.ptpb) {
        populations.push('intreatment');
      }

      return populations;
    }),

    userColorMap: {},
    // colors picked to be as far outside the red-orange-yellow-green of severity colors
    userColorScale: ['#1f77b4', '#00bbbb', '#338888', '#6688bb', '#113377', '#007777'],
    // gets the color for rendering a user in this graph
    getColorForUser: function getColorForUser(user) {
      var userColorMap = this.get('userColorMap');
      var userId = user.get('id');
      if (!userColorMap[userId]) {
        var numAssignedColors = Object.keys(userColorMap).length;
        var colorScale = this.get('userColorScale');
        userColorMap[userId] = colorScale[numAssignedColors % colorScale.length];
      }
      return userColorMap[userId];
    },


    scaleData: Ember.computed('allReports.[]', function () {
      var byScale = {};

      this.get('allReports').forEach(function (report) {
        report.get('computedValues').forEach(function (cv) {
          var scale = cv.belongsTo('scale').id();

          if (!byScale[scale]) {
            byScale[scale] = {
              all: []
            };
          }

          byScale[scale].all.push(cv);
        });
      });

      return byScale;
    }),

    // TODO: performance cache
    activeScaleData: Ember.computed('currentReportSet.[]', function () {
      var byScale = {};

      this.get('currentReportSet').forEach(function (report) {
        report.get('computedValues').forEach(function (cv) {
          // TODO: Change this to be by id
          var scale = cv.belongsTo('scale').id();

          if (!byScale[scale]) {
            byScale[scale] = [];
          }

          byScale[scale].push(cv);
        });
      });

      return byScale;
    }),

    selectedReport: Ember.computed('allReports.[]', function () {
      var reports = this.get("allReports");
      return reports.maxBy('patientSession.targetDate');
    }),

    anyReportIncludesNorse: Ember.computed('allReports.@each.includesNorse', function () {
      return this.get("allReports").any(function (report) {
        return report.get("includesNorse");
      });
    }),

    currentReportSet: Ember.computed("allReports.[]", "selectedReport", function () {
      var reports = this.get("allReports");
      var selectedReport = this.get("selectedReport");

      if (!reports || reports.length < 2) {
        return null;
      }

      var sortedReports = reports.sortBy("patientSession.targetDate").toArray();

      var selectedIndex = sortedReports.indexOf(selectedReport);

      return sortedReports.slice(0, selectedIndex + 1);
    }),

    selectedReportValuesByScaleMnemonic: Ember.computed('selectedReport', function () {
      return _lodash.default.groupBy(this.getWithDefault('selectedReport.computedValues', []).toArray(), function (cv) {
        return cv.get('scale.mnemonic');
      });
    }),

    selectedReportValuesByScaleBaseMnemonic: Ember.computed('selectedReport', function () {
      return _lodash.default.groupBy(this.getWithDefault('selectedReport.computedValues', []).toArray(), function (cv) {
        return cv.get('scale.baseMnemonic');
      });
    }),

    comparisonReport: Ember.computed("currentReportSet.[]", "selectedReport", "compareReportTo", function () {
      var reports = this.get("currentReportSet");
      var selectedReport = this.get("selectedReport");

      if (!reports || reports.length < 2) {
        return null;
      }

      if (this.get("compareReportTo") === 'previous') {
        var selectedIndex = reports.indexOf(selectedReport);

        if (selectedIndex === 0) {
          return null;
        }

        return reports[selectedIndex - 1];
      } else {
        return _lodash.default.head(reports);
      }
    }),

    // TODO: make this work properly rather than 
    otherSettings: Ember.computed(function () {
      var curves = this.get('etrCurves');

      var settings = {};

      if (curves) {
        settings.showEtrCurves = false;
      }

      return settings;
    }),

    // This is the object which contains all the different value by value comparisons
    reportComparison: Ember.computed('selectedReport', 'comparisonReport', function () {
      var activeReport = this.get("selectedReport");
      var comparisonReport = this.get("comparisonReport");
      if (!comparisonReport) {
        return null;
      }

      return _reportComparison.default.create({
        activeReport: activeReport,
        comparisonReport: comparisonReport,
        population: this.get("currentPopulation")
      });
    }),

    userScaleColorMap: {},
    defaultColorScale: d3.scale.category20().domain(d3.range(0, 19)),

    // Designed for graphs where there are potentially multiple scales AND
    // multiple users for each scale
    getColorForScale: function getColorForScale(scale, index) {
      return this.get("defaultColorScale")(index);
    }
  });
});