define('frontend/models/drilldown-metric', ['exports', 'frontend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    intl: Ember.inject.service(),
    path: _emberData.default.attr("string"),
    shortcode: _emberData.default.attr("string"),
    startDate: _emberData.default.attr("string"),
    endDate: _emberData.default.attr("string"),
    preset: _emberData.default.attr("string"),
    comparisonStartDate: _emberData.default.attr("string"),
    comparisonEndDate: _emberData.default.attr("string"),
    comparisonPreset: _emberData.default.attr("string"),
    flagIds: _emberData.default.attr(),
    instituteConditionIds: _emberData.default.attr(),
    includeGraph: _emberData.default.attr('boolean'),
    breakdown: _emberData.default.attr("string"),
    scale: _emberData.default.attr("string"),
    scaleScorerId: _emberData.default.attr("string"),
    scaleScorer: _emberData.default.belongsTo('assessment/scaleScorer'),

    sort: _emberData.default.attr('string'),
    sortDirection: _emberData.default.attr('string'),
    limit: _emberData.default.attr('number'),

    headers: _emberData.default.attr(),

    includeCsv: _emberData.default.attr('boolean'),
    csvData: _emberData.default.attr('string'),

    reportEntityTreeNodes: _emberData.default.hasMany('reportEntityTreeNode'),

    downloadCsv: function downloadCsv() {
      var _getProperties = this.getProperties('csvData', 'shortcode', 'startDate', 'endDate', 'preset'),
          csvData = _getProperties.csvData,
          shortcode = _getProperties.shortcode,
          startDate = _getProperties.startDate,
          endDate = _getProperties.endDate,
          preset = _getProperties.preset;

      var intlString = 'reporting.reps.' + shortcode + '.title';

      var title = this.get("intl").t(intlString);

      var presetString = preset ? this.get("intl").t('reporting.filtering.timeOptions.' + preset) : null;

      var name = startDate ? title + ' ' + startDate + '-' + endDate : title + ' ' + presetString;

      var file = new Blob([csvData], { type: 'text/csv' });

      var nav = window.navigator;
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(file);
        return;
      }

      var fileURL = URL.createObjectURL(file);

      var link = document.createElement("a");
      document.body.appendChild(link); //required in FF, optional for Chrome
      link.href = fileURL;
      link.download = name + '.csv';
      link.click();
      window.URL.revokeObjectURL(fileURL);
      link.remove();
    }
  });
});