define('frontend/models/assessment/report', ['exports', 'frontend/models/application', 'ember-data', 'frontend/models/assessment/invitation'], function (exports, _application, _emberData, _invitation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Some scales 'automatically' include other scales when they're administered. E.g. the SFSS splits into SFSS-I and
  // SFSS-E.
  var scaleProjections = {
    sfss: ['sfssI', 'sfssE'],
    sfssA: ['sfssI', 'sfssE', 'sfss'],
    sfssB: ['sfssI', 'sfssE', 'sfss'],
    sfss_C: ['sfssI_C', 'sfssE_C', 'sfssC'],
    sfssA_C: ['sfssI_C', 'sfssE_C', 'sfssC'],
    sfssB_C: ['sfssI_C', 'sfssE_C', 'sfssC']
  };

  exports.default = _application.default.extend({
    intl: Ember.inject.service(),
    preload: Ember.inject.service(),
    invitations: _emberData.default.hasMany("assessment/invitation", { async: true }),
    status: _emberData.default.attr('string'),
    isTestData: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    effectiveDate: _emberData.default.attr('date'),
    patient: _emberData.default.belongsTo('patient', { inverse: "assessmentReports" }),
    answers: _emberData.default.hasMany('assessment/answer'),
    computedValues: _emberData.default.hasMany('assessment/computedValue', { fetchAll: true }),
    scales: _emberData.default.hasMany('assessment/scale'),

    // This is only fetched if you ask for fieldset:all
    scaleMnemonics: _emberData.default.attr(),
    administeredScaleIds: _emberData.default.attr(),

    cachedScales: Ember.computed('preload.scales.[]', 'preload.scaleMap', 'scaleMnemonics.[]', function () {
      var store = this.get("store");
      return this.get('administeredScaleIds').map(function (s) {
        return store.peekRecord('assessment/scale', s);
      }).compact();
    }),

    reportScales: _emberData.default.hasMany('assessment/reportScale'),
    enabledReportScales: Ember.computed.filterBy('reportScales', 'disabled', false),
    blindedReportScales: Ember.computed.filterBy('enabledReportScales', 'blinded', true),
    visibleReportScales: Ember.computed.filterBy('enabledReportScales', 'blinded', false),

    visibleEnabledReportScales: Ember.computed.filterBy('visibleReportScales', 'disabled', false),

    patientSession: _emberData.default.belongsTo('patientSession'),
    assessment: _emberData.default.belongsTo('assessment/assessment', { async: true }),

    isPreliminary: Ember.computed('status', function () {
      return this.get("status") === "preliminary";
    }),
    isComplete: Ember.computed('status', function () {
      return this.get("status") === "complete";
    }),
    isPartial: Ember.computed('status', function () {
      return this.get("status") === "partial" || this.get('status') === 'processing';
    }),
    isIncomplete: Ember.computed.equal('status', 'incomplete'),
    hasData: Ember.computed('status', function () {
      var status = this.get("status");

      return ['partial', 'incomplete', 'complete'].indexOf(status) >= 0;
    }),

    validComputedValues: Ember.computed.filterBy("computedValues", "resultValid"),

    includesComputedNorse: Ember.computed('validComputedValues', function () {
      var computedValues = this.getWithDefault('validComputedValues', []);
      return computedValues.any(function (cv) {
        return cv.get('domain') === 'normed' && cv.get('scale.measureType') === 'norse';
      });
    }),

    includesNorse: Ember.computed('computedValues.[]', function () {
      var computedValues = this.getWithDefault('computedValues', []);
      return computedValues.any(function (cv) {
        return cv.get('scale.measureType') === 'norse';
      });
    }),

    // Note that we use the presence of a report scale for ptpb, not the computed values. This is because the
    // ptpb subscales may be administered separately
    includesComputedPeabody: Ember.computed('scales.[]', function () {
      var scales = this.getWithDefault('scales', []);
      return scales.any(function (scale) {
        return scale.get('mnemonic') === 'ptpb';
      });
    }),

    includesPeabody: Ember.computed('computedValues.[]', function () {
      var computedValues = this.getWithDefault('computedValues', []);
      return computedValues.any(function (cv) {
        return cv.get('scale.measureType') === 'ptpb';
      });
    }),

    includesAlerts: Ember.computed('computedValues.[]', function () {
      var computedValues = this.getWithDefault('validComputedValues', []);
      return computedValues.any(function (cv) {
        return cv.get('scale.measureType') === 'alert';
      });
    }),

    includesComputedDsm: Ember.computed('validComputedValues', function () {
      var computedValues = this.getWithDefault('validComputedValues', []);
      return computedValues.any(function (cv) {
        return cv.get('scale.measureType') === 'dsm5';
      });
    }),

    includesDsm: Ember.computed('computedValues.[]', function () {
      var computedValues = this.getWithDefault('computedValues', []);
      return computedValues.any(function (cv) {
        return cv.get('scale.measureType') === 'dsm5';
      });
    }),

    // We have a new system to configure alerts for the new feedback report, but it's not worth it right now
    // to update this whole logic considering we are trying to move away from this version of the feedback report.
    // So just hardcode the woodlands alert.
    includesWoodlandsAlert: Ember.computed('validComputedValues', function () {
      var computedValues = this.getWithDefault('computedValues', []);
      return computedValues.any(function (cv) {
        return cv.get('scale.mnemonic') === 'woodlandbsrsalert';
      });
    }),

    includesHolisticResponses: Ember.computed('holisticResponses', function () {
      return !!this.get('holisticResponses');
    }),

    includesSfss: Ember.computed('validComputedValues', function () {
      var computedValues = this.getWithDefault('validComputedValues', []);
      return computedValues.any(function (cv) {
        var mnemonic = cv.get('scale.baseMnemonic') || cv.get('scale.mnemonic');
        return mnemonic === 'sfss';
      });
    }),

    includesComputedAlliance: Ember.computed('validComputedValues', function () {
      var computedValues = this.getWithDefault('validComputedValues', []);
      return computedValues.any(function (cv) {
        var mnemonic = cv.get('scale.baseMnemonic') || cv.get('scale.mnemonic');
        return cv.get('domain') === 'normed' && mnemonic === 'alliance';
      });
    }),

    includesComputedMedication: Ember.computed('validComputedValues', function () {
      var computedValues = this.getWithDefault('validComputedValues', []);
      return computedValues.any(function (cv) {
        var mnemonic = cv.get('scale.baseMnemonic') || cv.get('scale.mnemonic');
        return ['medicationThoughts', 'ptpbMedCombined', 'ptpbMed', 'ptpbMed_C', 'medConcerns'].includes(mnemonic);
      });
    }),

    includesComputedSubstanceUse: Ember.computed('validComputedValues', function () {
      var computedValues = this.getWithDefault('validComputedValues', []);
      return computedValues.any(function (cv) {
        return ['sfssSubstanceUse', 'sfssSubstanceUse_C', 'sfssSubstanceUseCombined'].includes(cv.get('scale.baseMnemonic'));
      });
    }),

    holisticResponses: Ember.computed('validComputedValues.[]', function () {
      return this.get("validComputedValues").find(function (r) {
        return r.get("scale.baseMnemonic") === 'holisticResponses' || r.get("scale.baseMnemonic") === 'holisticResponsesShort';
      });
    }),

    standardValidValues: Ember.computed('validComputedValues.[]', function () {
      var allValues = this.get("validComputedValues");

      var excludedTypes = ['norse', 'ptpb', 'custom', 'indicator', 'module'];

      if (allValues) {
        return allValues.filter(function (v) {
          return excludedTypes.indexOf(v.get("scale.measureType")) < 0;
        });
      } else {
        return [];
      }
    }),

    norseValidValues: Ember.computed('validComputedValues.[]', function () {
      var computedValues = this.getWithDefault('validComputedValues', []);
      return computedValues.filter(function (cv) {
        return cv.get('scale.measureType') === 'norse';
      });
    }),

    peabodyValidValues: Ember.computed('validComputedValues.[]', function () {
      var computedValues = this.getWithDefault('validComputedValues', []);
      return computedValues.filter(function (cv) {
        return cv.get('scale.measureType') === 'ptpb';
      });
    }),

    customValidValues: Ember.computed('computedValues.[]', function () {
      return this.getWithDefault('computedValues', []).filterBy('scale.measureType', 'custom');
    }),

    offeredPatientScales: Ember.computed('focalInvitation', function () {
      return this.get('focalInvitation.offeredScales');
    }),

    allOfferedScales: Ember.computed('invitations.@each.offeredScales', function () {
      var scales = [];

      this.get("invitations").forEach(function (invitation) {
        var offered = invitation.get("offeredScales");
        if (offered) {
          invitation.get("offeredScales").forEach(function (mnemonic) {
            scales.push(mnemonic);

            if (scaleProjections[mnemonic]) {
              scales.pushObjects(scaleProjections[mnemonic]);
            }
          });
        }
      });

      return scales.uniq();
    }),

    patientInvitation: Ember.computed('invitations.[]', function () {
      return this.get('invitations').find(function (i) {
        return i.get('participant.isPatient');
      });
    }),

    focalInvitation: Ember.computed('invitations.[]', function () {
      // for now, it's just the first one
      return this.get('invitations').objectAt(0);
    }),

    nonExpiredInvitations: Ember.computed('invitations.[]', function () {
      return this.get("invitations").rejectBy('status', 'expired');
    }),

    recentChangesValues: Ember.computed("validComputedValues.[]", function () {
      return this.getWithDefault("validComputedValues", []).filter(function (r) {
        return r.get("scale.mnemonic") === 'recentChanges' || r.get("scale.measureType") === 'recentChanges';
      });
    }),

    recentChangesList: Ember.computed('recentChangesValues.[]', function () {
      var intl = this.get('intl');
      return this.get("recentChangesValues").mapBy('properties').flatten().map(function (item) {
        if (item.name) {
          // New style
          return item;
        } else {
          return {
            value: item,
            name: intl.t('question.recentChanges.option.' + item),
            icon: 'fa ' + intl.t('question.recentChanges.icon.' + item),
            code: intl.t('question.recentChanges.iconCode.' + item)
          };
        }
      });
    }),

    notDeletedInvitations: Ember.computed.filterBy("invitations", 'isDeleted', false),

    activeInvitations: Ember.computed('notDeletedInvitations.@each.status', function () {
      return this.get("notDeletedInvitations").rejectBy('status', 'not_required');
    }),

    currentPhase: Ember.computed("isNew", "patientSession.targetDate", "invitations.@each.status", function () {
      if (this.get("isNew")) {
        return 'before';
      }

      // https://mirah.atlassian.net/browse/LSB-894
      // Users get strange errors 

      // Even if we have a date, if no reports have been completed yet, force to before. That way, if you get the date slightly
      // wrong, or it's a walkin, then it doesn't automatically put you into 'during'.
      var hasCompleteBefore = this.get("invitations").filterBy("isMutable", false).any(function (invitation) {
        return invitation.get("phase") === 'before';
      });

      if (!hasCompleteBefore) {
        return 'before';
      }

      var minuteDiff = moment(this.get("patientSession.targetDate")).diff(new Date(), 'minutes');

      if (minuteDiff < -60) {
        return 'after';
      } else if (minuteDiff > 0) {
        return 'before';
      } else {
        return 'during';
      }
    }),

    activeSortedInvitations: Ember.computed("activeInvitations.[]", function () {

      // We want to sort consistently: patients go first, then related people, then providers. Phase order is before, during, after.
      var invitationSortScores = {
        participant: {
          patient: 1000,
          relatedPerson: 2000,
          provider: 3000
        },
        phase: {
          before: 1,
          during: 10,
          after: 20
        }
      };

      return this.getWithDefault("activeInvitations", []).toArray().sort(function (a, b) {
        var aScore = invitationSortScores.participant[a.get("participant.participantType")] + invitationSortScores.phase[a.get("phase")];
        var bScore = invitationSortScores.participant[b.get("participant.participantType")] + invitationSortScores.phase[b.get("phase")];
        return aScore - bScore;
      });
    }),

    // Can't have objects in groupBy keys, so do it by hand
    activeParticipantsWithInvitations: Ember.computed("activeSortedInvitations.[]", function () {
      var result = {};

      this.getWithDefault("activeSortedInvitations", []).forEach(function (invitation) {
        var participant = invitation.get("participant");
        var id = participant.get("id");

        if (!result[id]) {
          result[id] = {
            participant: participant,
            invitations: Ember.A()
          };
        }

        result[id].invitations.addObject(invitation);
      });

      // Object.values is not supported everywhere
      return Object.keys(result).map(function (key) {
        return result[key];
      });
    }),

    /* Business logic methods */

    // Represents shifts to the earliest allowed phase. E.g. if you're usually before, but we're during the session, you now
    // get administered 'during'
    currentPhaseMap: Ember.computed("currentPhase", function () {
      var currentPhase = this.get("currentPhase");

      var result = {};

      Object.keys(_invitation.PhaseMap).map(function (phase) {
        if (_invitation.PhaseMap[currentPhase] > _invitation.PhaseMap[phase]) {
          result[phase] = currentPhase;
        } else {
          result[phase] = phase;
        }
      });

      return result;
    }),

    incompleteInterviewInvitations: Ember.computed('invitations.[]', function () {
      return this.get('invitations').filterBy('format', 'interview').filterBy('requiresCompletion');
    }),

    incompleteProviderAfterSessionInvitations: Ember.computed('invitations.[]', function () {
      return this.get('invitations').filterBy('phase', 'after').filterBy('user.userType', 'provider').filterBy('requiresCompletion');
    }),

    startedInvitationsCount: Ember.computed('invitations.@earch.status', function () {
      return this.get('invitations').filter(function (invitation) {
        return ['begun', 'partial', 'complete'].includes(invitation.get('status'));
      }).length;
    }),

    autopopulateScales: function autopopulateScales() {
      var _this = this;

      var scales = this.getWithDefault("patient.defaultScales", []);

      scales.forEach(function (scale) {
        return _this.addScale(scale, true);
      });
    },
    toggleScale: function toggleScale(scale) {
      var reportScale = this.get('reportScales').findBy('scale.id', scale.get('id'));

      if (reportScale && !reportScale.get('isDeleted')) {
        return this.removeReportScale(reportScale);
      } else {
        return this.addScale(scale);
      }
    },


    // Add a scale, with default participants, and then add invitations if not present.
    addScale: function addScale(scale, defaultProviderOnly, format) {
      if (!format) {
        format = scale.get('defaultFormat');
      }
      var currentPhaseMap = this.get("currentPhaseMap");
      var reportScale = this.get('reportScales').findBy('scale.id', scale.get('id'));
      if (!reportScale) {
        reportScale = this.get("store").createRecord("assessment/reportScale", { scale: scale, manual: true, disabled: false, report: this, scaleParticipants: [], format: format, phase: currentPhaseMap[scale.get("defaultPhase")] });
      } else if (reportScale.get('isDeleted')) {
        reportScale.rollbackAttributes();
      }

      this.addParticipantsToScale(reportScale, this.get("patientSession.allParticipants"), defaultProviderOnly);
    },
    removeScale: function removeScale(scale) {
      var reportScale = this.get('reportScales').findBy('scale.id', scale.get('id'));

      if (reportScale) {
        this.removeReportScale(reportScale);
      }
    },
    removeReportScale: function removeReportScale(reportScale) {
      reportScale.deleteRecord();
      reportScale.get("scaleParticipants").forEach(function (rsp) {
        return rsp.deleteRecord();
      });

      this.removeInactiveInvitations();
    },
    addParticipantToScale: function addParticipantToScale(reportScale, participant) {
      return this.addParticipantsToScale(reportScale, [participant]);
    },
    changeScaleFormat: function changeScaleFormat(reportScale) {
      reportScale.get("scaleParticipants").forEach(function (rsp) {
        rsp.deleteRecord();
      });
      this.addParticipantsToScale(reportScale, this.get("patientSession.allParticipants"));
      this.removeInactiveInvitations();
    },


    // Add a participant to a scale, and add invitations if not present
    addParticipantsToScale: function addParticipantsToScale(reportScale, participants, defaultProviderOnly) {
      var _this2 = this;

      var patient = participants.findBy("participantType", "patient");
      var scale = reportScale.get('scale');
      if (scale && scale.content) {
        scale = scale.content;
      }

      var defaultParticipants = scale.activeParticipantsFor(patient);
      var phase = reportScale.get("phase");
      var format = reportScale.get("format");

      // For now, we only want to add a single provider to the scale. Choose the first one that comes along.
      var providerSelected = false;

      var defaultProvider = participants.find(function (p) {
        return _this2.get('patientSession.provider.id') === p.get('id');
      });

      //add the default participants adapted for the patient
      participants.forEach(function (p) {
        var participantType = p.get("participantType");
        if (defaultParticipants.includes(participantType)) {

          if (defaultProviderOnly && participantType === 'provider') {
            if (providerSelected) {
              return; // only one provider participant at a time for now
            } else {
              if (!defaultProvider || defaultProvider.get("id") === p.get("id")) {
                providerSelected = true;
              } else {
                return;
              }
            }
          }

          // If invitation does not exist, create one.
          var invitation = _this2.get("invitations").find(function (i) {
            return i.get("user.id") === p.get("user.id") && i.get("phase") === phase && i.get("isMutable") && i.get("format") == format;
          });

          if (!invitation) {
            invitation = _this2.get("store").createRecord("assessment/invitation", { report: _this2, user: p.get("user"), participant: p, phase: phase, format: format, status: 'new' });
            invitation.updateNotificationsToPreferences(true, _this2.get("patientSession.schedulingAppointment.startDate"));
            _this2.get("invitations").pushObject(invitation);
            if (_this2.get('onCreateInvitation')) {
              _this2.get('onCreateInvitation')(invitation);
            }
          }

          // You can technically get a deleted object
          if (invitation.get("isDeleted")) {
            if (invitation.content) {
              invitation.content.rollbackAttributes();
            } else {
              invitation.rollbackAttributes();
            }
          }

          var rsp = reportScale.get("scaleParticipants").findBy("participant.id", p.get("id"));

          if (rsp) {
            if (rsp.get("isDeleted")) {
              if (rsp.content) {
                rsp.content.rollbackAttributes();
              } else {
                rsp.rollbackAttributes();
              }
            }
          } else {
            rsp = _this2.get("store").createRecord("assessment/reportScaleParticipant", { reportScale: reportScale, user: p.get("user"), participant: p, invitation: invitation });
            reportScale.get('scaleParticipants').pushObject(rsp);
          }

          invitation.get("reportScaleParticipants").pushObject(rsp);
        }
      });
    },
    removeParticipantFromScale: function removeParticipantFromScale(reportScale, participant) {
      var rsp = reportScale.get('scaleParticipants').find(function (rsp) {
        return rsp.get('participant') === participant;
      });

      rsp.deleteRecord();

      this.removeInactiveInvitations();
    },
    removeInactiveInvitations: function removeInactiveInvitations() {
      // Get list of all required participants
      // Delete any invitation without.
      var activeParticipantsByPhaseAndFormat = {};

      this.getWithDefault("reportScales", []).map(function (reportScale) {
        // If the record has already been saved (i.e. we are editing) then
        // the delete call will not remove this element. We keep it hidden here
        // instead. If it needs to be deleted, then it'll be removed on the server
        // side as part of the nested data push.
        if (!reportScale.get("disabled") && !reportScale.get("isDeleted") && reportScale.get('isSelected')) {
          var phase = reportScale.get("phase");
          var format = reportScale.get("format");
          var key = phase + '-' + format;

          if (!activeParticipantsByPhaseAndFormat[key]) {
            activeParticipantsByPhaseAndFormat[key] = {};
          }

          reportScale.get("scaleParticipants").filterBy("isDeleted", false).map(function (rsp) {
            activeParticipantsByPhaseAndFormat[key][rsp.get("participant.id")] = rsp.get("participant");
          });
        }
      });

      // Deleting the records inline causes array problems, so do it afterwards.
      var recordsToDelete = [];
      this.get("invitations").forEach(function (invitation) {
        if (invitation) {
          var phase = invitation.get("phase");
          var format = invitation.get("format");
          var key = phase + '-' + format;
          if (!activeParticipantsByPhaseAndFormat[key] || !activeParticipantsByPhaseAndFormat[key][invitation.get("participant.id")]) {
            recordsToDelete.pushObject(invitation);
          }
        }
      });

      recordsToDelete.forEach(function (invitation) {
        invitation.deleteRecord();
      });
    }
  });
});