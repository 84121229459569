define('frontend/pods/components/provider/navigation-menu/component', ['exports', 'frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    reports: Ember.inject.service(),
    screen: Ember.inject.service(),

    userTaskService: Ember.inject.service('provider/taskService'),
    tagName: '',
    sessionUser: Ember.inject.service(),
    showReportsOption: Ember.computed.alias('reports.userHasViewableReports'),

    showDemoUserCreate: Ember.computed('features.enableDemoQuickAdd', function () {
      return _environment.default.enableDemoLoop && this.get('features.enableDemoQuickAdd');
    }),
    // TODO: once roll out of the complete is complete
    // REMOVE this feature gate
    enableDashboard: Ember.computed('features.enableDashboardSchedule', function () {
      return this.get("features.enableDashboardSchedule");
    }),

    enableCollaborativeCare: Ember.computed('features.enableCollaborativeCare', function () {
      return this.get('features.enableCollaborativeCare');
    }),

    showOutcomes: Ember.computed(function () {
      // The outcomes metrics features is only available to superusers.
      this.get('sessionUser').hasAnyRole(['superuser', 'Sub Unit Supervisor', 'Supervisor']);
    })
  });
});