define("frontend/models/institute", ["exports", "frontend/models/application", "ember-data", "ember-cp-validations", "frontend/mixins/validation-helper"], function (exports, _application, _emberData, _emberCpValidations, _validationHelper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.configurationData = exports.featureConfiguration = undefined;


  var validationOptions = {
    shortcode: (0, _emberCpValidations.validator)("presence", true),
    name: (0, _emberCpValidations.validator)("presence", true)
  };

  var Validations = (0, _emberCpValidations.buildValidations)(validationOptions);

  var featureConfiguration = {
    enableMeasurementPlans: {
      name: "Measurement Plans",
      group: "Features",
      type: "boolean",
      default: true,
      description: "Enable full measurement planning for the organization.",
      experimental: true,
      demo: false
    },
    enableConditions: {
      name: "Conditions/Diagnoses",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Allows providers to set list of conditions/diagnoses for a patient and do reporting on them. Still experiment, not ready for roll out yet.",
      experimental: true,
      demo: false
    },
    enableDemoQuickAdd: {
      name: "Demo Quick Add",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Enables the 'quick add patient' button suitable for demos. Don't enable in production, it won't work.",
      experimental: false,
      demo: true
    },
    enablePayors: {
      name: "Payor Functionality",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Allows the collection of insurance information and shows analytics by insurer",
      experimental: true,
      demo: false
    },
    enableBilling: {
      name: "Billing Functionality",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Allows use of the reimbursement engine",
      experimental: true,
      demo: false
    },
    enableExperiments: {
      name: "Experiments Functionality",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Allows RCTS and other randomized experiments",
      experimental: true,
      demo: false
    },
    enableCareUnits: {
      name: "Care Units/Group Therapy/Family",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Allows non-patient units of treatment, e.g. group therapy and family therapy",
      experimental: true,
      demo: false
    },
    enableMeasurementDimensions: {
      name: "Measurement Dimensions",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Turns on dimensional measurement including the new feedback report",
      experimental: true,
      demo: false
    },
    enableOptumMetrics: {
      name: "Optum Metrics",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Turns on information on optum metrics in the analytics tab",
      experimental: true,
      demo: false
    },
    enableQualityIssues: {
      name: "Data Quality Framework",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Enables the data quality check framework and workflows",
      experimental: true,
      demo: false
    },
    enableDashboardSchedule: {
      name: "Roll Out Dashboard Schedule",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Enables the first page (fka Dashboard) a user sees when logging in to be the Schedule",
      experimental: false,
      demo: false
    },
    enableAthenaWriteback: {
      name: "Athena Writeback",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Enables automatically sending PHQ-9 and GAD-7 reports to customer's Athena EHR",
      experimental: false,
      demo: false
    },
    enableDecisionSupport: {
      name: "Enable Decision Support (Geode Beta)",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Enable decision support emails for Geode",
      experimental: false,
      demo: false
    },
    enableTreatmentServices: {
      name: "Enable Treatment Services",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Whether or not Treatment Services are enabled for this institute"
    },
    enableHealthieChildPatientSwap: {
      name: "Enable Healthie Child Patient Swap",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Whether or not we'll swap contact information for an under 18 patient with at least one caregiver from a single child notification contact. Only works with the Healthie integration."
    },
    enableCollaborativeCare: {
      name: "Enable Collaborative Care",
      group: "Collaborative Care",
      type: "boolean",
      default: false,
      description: "Whether or not this is a collaborative care ONLY institute"
    },
    assessmentIncompleteFindings: {
      name: "Enable time elapsed since invitation findings",
      group: "Collaborative Care",
      type: "boolean",
      default: false,
      description: "Create findings 7 days after an invitation is sent and has not been not completed"
    },
    monthlyAssessmentMissingFindings: {
      name: "Enable monthly assessment not complete findings",
      group: "Collaborative Care",
      type: "boolean",
      default: false,
      description: "Create findings when a patient has no measures completed 7 days before the end of the month"
    },
    enableImplementationTargets: {
      name: "Enable implementation targets",
      group: "Collaborative Care",
      type: "boolean",
      default: false,
      description: "Enables the implementation target framework where you can set and track implementation goals"
    },
    enableLocations: {
      name: "Enable Locations",
      group: "Features",
      type: "boolean",
      default: false,
      description: "Enables locations"
    }
  };

  var configurationData = {
    integrationEnabled: {
      name: "Enable EHR Integration",
      group: "Integrations",
      type: "boolean",
      default: false,
      description: "whether uploaded files should be processed"
    },
    integrationScheduled: {
      name: "EHR Integration Schedule",
      group: "Integrations",
      type: "boolean",
      default: true,
      description: "whether we should expect regular delivery of integration files. If false, assume files will be uploaded manually at some undefined interval"
    },
    appointmentIntegrationExists: {
      name: "EHR Integration includes appointments",
      group: "Integrations",
      type: "boolean",
      default: false,
      description: "whether this client receives scheduled appointments from an EHR"
    },
    careEpisodeIntegrationEnabled: {
      name: "EHR Integration includes episodes of care",
      group: "Integrations",
      type: "boolean",
      default: false,
      description: "whether we import care episodes from the ehr. Changes the behavior of the appointment processor as it will be less aggressive creating new care episodes, assuming that the EMR has already told them about it."
    },
    careEpisodeDisableManualCreate: {
      name: "Disable Manual Care Episode Creation",
      group: "Integrations",
      type: "boolean",
      default: false,
      description: "whether users can manually create episodes of care in the UI."
    },
    integrationAutoCreateSessionsForProvider: {
      name: "EHR Integration Auto Create Session For Provider",
      group: "Integrations",
      type: "boolean",
      default: true,
      description: "whether the default is to create sessions from appointments when integration is enabled per provider. If false, individual providers need to be opted in."
    },
    integrationAutoCreateCareEpisodes: {
      name: "EHR Integration Auto Create Care Episodes",
      group: "Integrations",
      type: "boolean",
      default: true,
      description: "Automatically create care episodes if none can be found. If false, when no care episode can be found, no session will be created."
    },
    enabledNameFields: {
      name: "Enabled Name Fields",
      group: "Integrations",
      type: "string_array",
      default: ["legal_first_name", "legal_last_name", "preferred_first_name"],
      description: "Which fields are editable and present on the patient demographics page. This is not settable directly but is instead a configuration set on the basis of the active EHR integration."
    },
    sendSmsReminders: {
      name: "Enable SMS Messaging (Leave enabled)",
      group: "Leave Alone",
      type: "boolean",
      default: true,
      description: "Send SMS reminders if a phone number is present and a reminder is configured to be sent. LEAVE THIS ON. It should be removed once final customers without it on are confirmed"
    },
    supportsCoppaForChildren: {
      name: "(COPPA) Enable self-report measurement for children under 13",
      group: "General",
      type: "boolean",
      default: true,
      description: "Whether the institute has processes in place for acquiring consent for self-report for children under 13 under COPPA and should allow measurement"
    },
    showCareEpisodeSelector: {
      name: "Show Care Episode Selector",
      group: "General",
      type: "boolean",
      default: false,
      description: "Show the care episode selector when creating sessions. Useful when patients have multiple ongoing treatments"
    },
    loginCodeValidityMinutes: {
      name: "Login Code Validity Period (minutes)",
      type: "number",
      group: "Leave Alone",
      default: 15,
      description: "How long instant login codes are valid for (in minutes)"
    },
    researchPatientSet: {
      name: "Research Patient Set Settings (Uminn Only)",
      group: "Legacy - Do not use",
      type: "json",
      advanced: true,
      default: { questionsIncluded: [] },
      description: "the questions that will be attached to patients in the patient research data set export."
    },
    hideMrn: {
      name: "Hide MRN",
      group: "General",
      type: "boolean",
      default: false,
      description: "hide MRN from all forms. Used when the MRNS are not real keys but instead integration composites"
    },
    billingChargeOnSurveyBegin: {
      name: "Charge on survey begin",
      group: "Billing",
      type: "boolean",
      default: false,
      description: "If true, will begin charges when the patient begins rather than completes a survey"
    },
    billingChargeWhenSessionCanceled: {
      name: "Charge when appointment canceled",
      group: "Billing",
      type: "boolean",
      default: true,
      description: "If true, will charge when a survey has been completed but the appointment backing it never took place"
    },
    billingChargeWhenNoAppointment: {
      name: "Charge when no appointment",
      group: "Billing",
      type: "boolean",
      default: false,
      description: "If true, will charge when a survey has been completed but there is no appointment backing it"
    },
    billingChargeProviderScale: {
      name: "Charge for provider scale",
      group: "Billing",
      type: "boolean",
      default: false,
      description: "If true, will charge when a survey has been completed by a provider"
    },
    billingChargeIgnoreAppointments: {
      name: "Ignore appointments for billing",
      group: "Billing",
      type: "boolean",
      default: false,
      description: "Automatically mark manual appointments as fulfilled afterwards. This is important for things like reimbursements where you need an appointment to occur"
    },
    allowBulkExport: {
      name: "Allow Bulk download (Genoa only)",
      group: "Legacy - Do not use",
      type: "boolean",
      default: true,
      description: "If true, users with appropriate permissions may use the bulk export functions"
    },
    enableWebhooks: {
      name: "Enable webhooks",
      group: "Integrations",
      type: "boolean",
      default: false,
      description: "If true, webhooks can be sent to other servers"
    },
    enableScaleBlinding: {
      name: "Enable scale blinding",
      group: "Research",
      type: "boolean",
      default: false,
      description: "If true, you can set scales to be blinded as part of research"
    },
    enableProviderConsentAdministration: {
      name: "Enable Provider Consent Administration [Leave mostly alone]",
      group: "Research",
      type: "boolean",
      default: true,
      description: "If true, providers can administer patient consents from the patient details page, as well as having the patients take them as part of an assessment"
    },
    integrationAppointmentDeduplicationWindowMinutes: {
      name: "Appointment Deduplication Window",
      group: "Integrations",
      type: "number",
      default: 0,
      description: "The window at which to not create new measurements from integrated appointments. 0 disables this."
    },
    cocmLateSessionTaskDays: {
      name: "Number of Days",
      group: "Collaborative Care",
      type: "number",
      default: 10,
      description: "The # of days after the session target date to create a task for care managers to follow up on incomplete sessions"
    },
    integrationOnlyMeasurePrimaryProvider: {
      name: "Measure Primary Clinician Only",
      group: "Integrations",
      type: "boolean",
      default: false,
      description: "If true, only measure sessions from the primary provider specified. Upon patient creation, the provider will automatically be set to the first provider with an appointment."
    },
    integrationNewProviderDefaultRole: {
      name: "New Clinician Default Role",
      group: "Integrations",
      type: "string",
      default: "clinician",
      description: 'Select which role new providers should be defaulted to be created as. You probably only want one of: "clinician", "Sub Unit Clinician", "Independent Clinician"'
    },
    integrationNewProviderMeasurementAllowed: {
      name: "New Clinician Measurement Allowed",
      group: "Integrations",
      type: "boolean",
      default: true,
      description: "Whether newly created clinicians have measurement allowed set to true or false when received from the EMR."
    },
    integrationUpdatePrimaryProvider: {
      name: "Update Primary Provider from Appointments",
      group: "Integrations",
      type: "boolean",
      default: false,
      description: "If true, the patient's primary provider will be automatically set to the last provider for which we processed an appointment."
    },
    integrationCareEpisodeRequiresTreatmentService: {
      name: "Require Treatment Service to create care episodes",
      group: "Integrations",
      type: "boolean",
      default: false,
      description: "If true, no care episodes or sessions will be created unless a treatment service matches. Use this to ensure that only exactly cases we understand are measured."
    },
    enableSeveritySummary: {
      name: "Enable Severity Summary",
      group: "Measures",
      type: "boolean",
      default: true,
      description: "Whether to show the severity indicator in the dashboard and appointment pages"
    },
    optumPilotMode: {
      name: "Optum Pilot Mode",
      group: "Custom Configuration",
      type: "boolean",
      default: false,
      description: "If true, enable extra features and configuration specific to Optum including GINA consent administration"
    },
    optumMetricsIncludedServiceTypes: {
      name: "Optum Metrics Included Service Types",
      group: "Custom Configuration",
      type: "string_array",
      default: [],
      description: "List of service types/billing codes that will be counted for adherence or report viewing results calculation"
    },
    uminnLegacyMode: {
      name: "Legacy Configuration for Uminn/Navigate",
      group: "Legacy - Do not use",
      type: "boolean",
      default: false,
      description: "A set of configuration used by Uminn/navigate that is for research use and not well suited for other customers."
    },
    mcleanLegacyMode: {
      name: "Legacy Configuration for Mclean",
      group: "Legacy - Do not use",
      type: "boolean",
      default: false,
      description: "A set of configuration used by Mclean research that is for research use and not well suited for other customers."
    },
    paceLegacyMode: {
      name: "Legacy Configuration for HCA/PACE",
      group: "Legacy - Do not use",
      type: "boolean",
      default: false,
      description: "A set of configuration used by HCA/PACE research that is for research use and not well suited for other customers."
    },
    reportDefaultTextMode: {
      name: "Report Text Style (Kristi House)",
      group: "Legacy - Do not use",
      type: "select",
      options: ["severity", "alphabetic"],
      default: "severity",
      description: "DEPRECATED (Used only by Kristi House) The default mode for the provider text report. Valid values: 'severity' or 'alphabetic'"
    },
    failAllMissedSessions: {
      name: "Mark All Missed Sessions As Failed (BCSC only)",
      group: "Legacy - Do not use",
      type: "boolean",
      default: false,
      description: "DEPRECATED (BCSC only) If true, all uncanceled sessions that have not been completed are marked as failed for adherence purposes. If false, only sessions with fulfilled appointments will be considered failed"
    },
    cambridgeSchoolsMode: {
      name: "Cambridge Schools Extra Fields",
      group: "Legacy - Do not use",
      type: "boolean",
      default: false,
      description: "Shows extra fields added to patients at the request of Cambridge Public Schools and not useful for other customers"
    },
    disableSurveyContactDetails: {
      name: "Disable Survey Contact Details",
      group: "Legacy - Do not use",
      type: "boolean",
      default: false,
      description: "Disables the automatic display of the screen to let users update their contact details and notification prefs"
    },
    disableSurveyConsent: {
      name: "Disable Survey Consent",
      group: "Legacy - Do not use",
      type: "boolean",
      default: false,
      description: "Disables the survey consent screen. This should only be used in situations like school systems where consent has been acquired by a different mechanism."
    }
  };

  exports.featureConfiguration = featureConfiguration;
  exports.configurationData = configurationData;
  exports.default = _application.default.extend(Validations, _validationHelper.default, {
    shortcode: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    tin: _emberData.default.attr("string"),
    featureSet: _emberData.default.attr(),
    features: _emberData.default.attr(),
    ssoSettings: _emberData.default.attr("object"),
    configuration: _emberData.default.attr(),
    configurationWithDefaults: _emberData.default.attr(),

    inactivityTimeoutSeconds: _emberData.default.attr("number"),

    enabled: _emberData.default.attr("boolean", { defaultValue: true }),

    timezone: _emberData.default.attr("string", { defaultValue: "America/New_York" }),
    patientAuthenticationMethod: _emberData.default.attr("string"),
    scales: _emberData.default.hasMany("assessment/scale"),
    isTest: _emberData.default.attr("boolean"),
    hasSupportAccounts: _emberData.default.attr("boolean"),
    createMeasurementPlans: _emberData.default.attr("boolean"),
    organizations: _emberData.default.hasMany("organization"),
    instituteScales: _emberData.default.hasMany("assessment/institute-scale"),

    defaultAuthenticationMechanism: _emberData.default.attr("string"),

    enabledScaleCount: Ember.computed("instituteScales.[]", function () {
      return this.get("instituteScales").mapBy("internalScale").filterBy("independent").get("length");
    }),

    requiredPatientFields: Ember.computed("patientAuthenticationMethod", function () {
      var method = this.getWithDefault("patientAuthenticationMethod", "full");

      // Note that this is more relaxed than the actual authentication required because
      // you can leave the ssn field to get the patient to enter it and use it on
      // subsequent attempts
      var requiredMap = {
        full: ["dob"],
        mrn_only: [],
        mrn_ssn: ["lastSsn"],
        dob_only: ["dob"],
        none: []
      };

      return requiredMap[method];
    })
  });
});